import React, { useState } from 'react'
import ContentWrapper from '../components/contentwrapper/ContentWrapper'
import { Helmet } from 'react-helmet'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getSingleDataFromDatabase } from '../utils/api'
import TopAgentProduct from '../components/dashboard/TopAgentProduct'
import TopSellingProducts from '../components/dashboard/TopSellingProducts'
import MostPopularOrderLocation from '../components/dashboard/MostPopularOrderLocation'
import TopCustomer from '../components/dashboard/TopCustomer'
import DashboardSection from '../components/dashboard/DashboardSection'
import DashboardOverview from '../components/dashboard/DashboardOverview'

const Dashboard = () => {
    const navigate = useNavigate();
    const centerid = localStorage.getItem('centerdata');
    const [center, setCenter] = useState([]);

    useEffect(() => {
        if (centerid) {
            navigate('/dashboard');
            getDashboardSingleEmpData(centerid);
        }
        else {
            navigate("/");
        }
    }, [navigate, centerid]);

    const getDashboardSingleEmpData = (centerid) => {
        getSingleDataFromDatabase(`center-get-single-member-data/${centerid}`).then((res) => {
            if (res.data.status === 1) {
                setCenter(...[res.data.centerdata]);
            }
            else {
                toast.error("Check you connection");
            }
        })
    }

    return (
        <div className="misDashboard">
            <Helmet>
                <title>SATKARTAR:CENTER::DASHBOARD</title>
            </Helmet>
            <ContentWrapper>
                {(() => {
                    let today = new Date();
                    let curHr = today.getHours();
                    if (curHr < 12) {
                        return (
                            <h4 className="dashboardname">Good Morning, <span>{center?.center_name}</span></h4>

                        )
                    }
                    else if (curHr < 17) {
                        return (
                            <h4 className="dashboardname">Good Afternoon, <span>{center?.center_name}</span></h4>
                        )
                    }
                    else {
                        return (
                            <h4 className="dashboardname">Good Evening, <span>{center?.center_name}</span></h4>
                        )
                    }
                })()}
                <div className="">
                    <DashboardSection />
                </div>
                <div className="">
                    <DashboardOverview />
                </div>
                <div className="row chartsection">
                    <div className="col-12 col-lg-3 col-xl-3">
                        <TopAgentProduct />
                    </div>
                    <div className="col-12 col-lg-3 col-xl-3">
                        <TopSellingProducts />
                    </div>
                    <div className="col-12 col-lg-3 col-xl-3">
                        <MostPopularOrderLocation />
                    </div>
                    <div className="col-12 col-lg-3 col-xl-3">
                        <TopCustomer />
                    </div>
                </div>
                {/* End */}
            </ContentWrapper>
        </div>
    )
}

export default Dashboard
