import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useNavigate, useParams } from 'react-router-dom'
import ContentWrapper from '../components/contentwrapper/ContentWrapper'
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { FaProductHunt, FaRegHandPointRight } from 'react-icons/fa';
import { toast } from 'react-toastify';
import {editDataFromList, updateDataAPI } from '../utils/api';

const initialState = {
    mis_name: '',
    mis_emp_id: '',
    mis_type: '',
}

const EditMember = () => {
    const [validated, setValidated] = useState(false);
    const [values, setMember] = useState(initialState);
    const navigate = useNavigate();
    const centerid = localStorage.getItem('centerdata');
    const { slug } = useParams();

    useEffect(() => {
        if (centerid) {
            navigate(`/edit-member/${slug}`);
            getSingleMemberData(slug);
        }
        else {
            navigate("/");
        }
    }, [centerid, slug]);

    const getSingleMemberData = (slug) => {
        editDataFromList(`center-get-single-center-member/${slug}`).then((res) => {
            if (res.data.status === 1) {
                setMember(...[res.data.memberdata])
            }
        })
    }
    const handleUpdateMember = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        else {
            try {
                updateDataAPI(`center-update-center-member-data/${slug}`, values).then((res) => {
                    if (res.data.status === 1) {
                        navigate('/member');
                        toast.success("Member Data has been updated successfully!!");
                    }
                    else if (res.data.status === 2) {
                        toast.warning("This Member already exit!!");
                    }
                    else {
                        toast.error("Something Wrongs!!");
                    }
                })
            }
            catch (error) {
                toast.error("Connection failed!!")
            }
        }
        setValidated(true);
    }
    const handleInputs = e => {
        setMember({
            ...values,
            [e.target.name]: e.target.value,
        });
    };
    return (
        <div className="misDashboard">
            <Helmet>
                <title>SATKARTAR:WAREHOUSE::EDIT MEMBER</title>
            </Helmet>
            <ContentWrapper>
                <div className="page-wrapper breadcum">
                    <div className="page-breadcrumb btnbreadcrum d-none d-sm-flex align-items-center justify-between">
                        <div className="">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 p-0">
                                    <li className="breadcrumb-item">
                                        <Link to="/dashboard">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item" aria-current="page">EDIT Member</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="col-6 col-lg-6 col-xl-6 card cardform">
                        <div className="card border-top border-0 border-4 border-danger">
                            <div className="card-body">
                                <div className="card-title d-flex align-items-center">
                                    <div className="form-heading">
                                        <FaProductHunt className="formIcons" />
                                        <h5 className="mb-0 text-danger">EDIT Member</h5>
                                    </div>
                                </div>
                                <hr />
                                <Form noValidate validated={validated} onSubmit={handleUpdateMember} method="post">
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="6" controlId="validationCustom01">
                                            <Form.Label>Name</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Name"
                                                name="mis_name"
                                                onChange={handleInputs}
                                                defaultValue={values?.mis_name}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid name
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="validationCustom02">
                                            <Form.Label>EMP ID</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Enter the emp id"
                                                name="mis_emp_id"
                                                onChange={handleInputs}
                                                pattern="[^\s]+"
                                                defaultValue={values?.mis_emp_id}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid emp id without white space
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="validationCustom03">
                                            <Form.Label>Select Member Type</Form.Label>
                                            <Form.Select required
                                                placeholder="Member Type"
                                                name="mis_type"
                                                onChange={handleInputs}>
                                                <option value={values?.mis_type}>{values?.mis_type}</option>
                                                <option value="MIS">MIS</option>
                                                <option value="AMIS">AMIS</option>
                                                <option value="VERIFIER">VERIFIER</option>
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid member
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>
                                    <Button type="submit">Update</Button>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default EditMember