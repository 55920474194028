import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import ContentWrapper from '../components/contentwrapper/ContentWrapper'
import { Link, useNavigate } from 'react-router-dom';
import { activeStatus, fetchAllDataByIDAPI, removeDataFromList } from '../utils/api';
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton';
import { FaEdit, FaEye, FaPlus, FaTrashAlt } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { Image, Modal, Table } from 'react-bootstrap';
import { BASEMISURLS } from '../utils/BaseUrl';
import moment from 'moment';

const Member = () => {
    const [member, setMember] = useState([]);
    const [agent, setAgent] = useState([]);
    const [isLoading, setIsloading] = useState(false);
    const centerid = localStorage.getItem('centerdata');
    const navigate = useNavigate();
    const [show, setShow] = useState(false);

    useEffect(() => {
        if (centerid) {
            navigate(`/member`);
            getAllMember(centerid)
        }
        else {
            navigate("/");
        }
    }, [centerid]);

    const handleClose = () => setShow(false);
    const handleAgentList = (memberid) => {
        setShow(true);
        fetchAllDataByIDAPI(`center-age-member-agent-data/${memberid}`).then((res) => {
            if (res.data.status === 1) {
                setIsloading(false);
                setAgent(...[res.data.agentdata]);
            }
            else {
                setIsloading(false);
                toast.warning("Check your connection")
            }
        })
    }
    const getAllMember = (centerid) => {
        setIsloading(true);
        fetchAllDataByIDAPI(`center-all-center-member-data/${centerid}`).then((res) => {
            if (res.data.status === 1) {
                setIsloading(false);
                setMember(...[res.data.memberdata]);
            }
            else {
                setIsloading(false);
                toast.warning("Check your connection")
            }
        })
    }

    //const exportOrder = cmsorder;
    const handleMemberRemove = (misid) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'danger',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
        }).then((result) => {
            if (result.isConfirmed) {
                removeDataFromList('center-remove-center-member-data', misid).then((res) => {
                    if (res.data.status === 1) {
                        Swal.fire(
                            'Deleted!',
                            'Your file has been deleted.',
                            'success'
                        )
                        getAllMember();
                    }
                })
            }
            else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {
                Swal.fire(
                    'Cancelled',
                    'Your imaginary file is safe :)',
                    'error'
                )
            }
        })
    }
    const handleMemberActive = (misid) => {
        console.log(misid);
        activeStatus('center-active-center-member-status', misid).then((res) => {
            if (res.data.status === 1) {
                getAllMember(centerid);
            }
        })
    }
    const handleMemberInactive = (misid) => {
        activeStatus('center-inactive-center-member-status', misid).then((res) => {
            if (res.data.status === 1) {
                getAllMember(centerid);
            }
        })
    }
    return (
        <div className="misDashboard">
            <Helmet>
                <title>SATKARTAR:CENTER::MIS AND AMIS</title>
            </Helmet>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Agent List</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>EMP ID</th>
                                <th>Status</th>
                                <th>Create Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {agent?.map((agent, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{agent?.agent_name}</td>
                                    <td><span className="badge badge-warning">{agent?.agent_emp_id}</span></td>
                                    <td>
                                        {(() => {
                                            if (agent?.agent_status === "Active") {
                                                return (
                                                    <button className="badge bg-success btnstatus">Active</button>
                                                )
                                            }
                                            else {
                                                return (
                                                    <button className="badge bg-danger btnstatus">Inactive</button>
                                                )
                                            }
                                        })()}
                                    </td>
                                    <td>{moment(agent?.agent_create_date).format('Do MMMM YYYY')}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Modal.Body>
            </Modal>
            <ContentWrapper>
                <div className="page-wrapper breadcum">
                    <div className="page-breadcrumb btnbreadcrum d-none d-sm-flex align-items-center justify-between">
                        <div className="">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 p-0">
                                    <li className="breadcrumb-item">
                                        <Link to="/dashboard">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item" aria-current="page">Add MIS and AMIS</li>
                                </ol>
                            </nav>
                        </div>
                        <Link to="/add-member" className="btn btn-md btn-info"><FaPlus /> Add MIS and AMIS</Link>
                    </div>
                    <div className="col-12 col-lg-12 col-xl-12">
                        <div className="card tablecard">
                            <div className="card-body">
                                <Table striped bordered hover size="sm" responsive="sm">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Image</th>
                                            <th>Name</th>
                                            <th>EMP ID</th>
                                            <th>Member Type</th>
                                            <th>Last Login</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {isLoading ?
                                            <>
                                                <tr>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                </tr>
                                                <tr>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                </tr>
                                                <tr>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                </tr>
                                                <tr>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                </tr>
                                                <tr>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                </tr>
                                                <tr>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                </tr>
                                                <tr>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                </tr>
                                                <tr>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                </tr>
                                                <tr>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                </tr>
                                                <tr>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                    <td><Skeleton animation="wave" variant="text" /></td>
                                                </tr>
                                            </>
                                            :
                                            member?.map((member, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td><Image className="rounded-circle" width={30} src={BASEMISURLS(member?.mis_foto)} /></td>
                                                    <td>{member?.mis_name}</td>
                                                    <td>{member?.mis_emp_id}</td>
                                                    <td>{member?.mis_type}</td>
                                                    <td>{member?.mis_last_login}</td>
                                                    <td>
                                                        {(() => {
                                                            if (member?.mis_status === "Active") {
                                                                return (
                                                                    <button className="badge bg-success btnstatus" onClick={() => handleMemberActive(member?.center_mis_id)}>Active</button>
                                                                )
                                                            }
                                                            else {
                                                                return (
                                                                    <button className="badge bg-danger btnstatus" onClick={() => handleMemberInactive(member?.center_mis_id)}>Inactive</button>
                                                                )
                                                            }
                                                        })()}
                                                    </td>
                                                    <td>
                                                        <div className="btnsection">
                                                            <button className="btnDisplay" onClick={() => handleAgentList(member?.center_mis_id)}><FaEye /></button>
                                                            <Link to={`/edit-member/${member?.center_mis_id}`} className="btnEdit"><FaEdit /></Link>
                                                            <button className="btnDelete" onClick={() => handleMemberRemove(member?.center_mis_id)}><FaTrashAlt /></button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th>#</th>
                                            <th>Image</th>
                                            <th>Name</th>
                                            <th>EMP ID</th>
                                            <th>Member Type</th>
                                            <th>Last Login</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </tfoot>
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default Member