import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import ContentWrapper from '../components/contentwrapper/ContentWrapper'
import { Link, useNavigate } from 'react-router-dom';
import { fetchAllDashboardDataAPI, fetchFilterDataAPI } from '../utils/api';
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton';
import { FaRegEye } from 'react-icons/fa';
import { Badge, Button, Table } from 'react-bootstrap';
import moment from 'moment';
import { numberFormat } from '../components/numberFormat';
import { FiRefreshCcw } from "react-icons/fi";

const AllOrder = () => {
    const [agentorder, setAgentOrder] = useState([]);
    const [isLoading, setIsloading] = useState(false);
    const centerid = localStorage.getItem('centerdata');
    const navigate = useNavigate();

    const [currentPage, setCurrentPage] = useState(1);
    const recordPerPage = 50;
    const lastIndex = currentPage * recordPerPage;
    const firstIndex = lastIndex - recordPerPage;
    const records = agentorder.slice(firstIndex, lastIndex);
    const npage = Math.ceil(agentorder.length / recordPerPage)
    const numbers = [...Array(npage + 1).keys()].slice(1)

    useEffect(() => {
        if (centerid) {
            navigate('/all-orders');
            getAllCenterCreatedOrder(centerid);
        }
        else {
            navigate("/");
        }
    }, [centerid]);

    const getAllCenterCreatedOrder = (centerid) => {
        setIsloading(true);
        fetchAllDashboardDataAPI('center-get-all-created-order-data', centerid).then((res) => {
            if (res.data.status === 1) {
                setIsloading(false);
                setAgentOrder(...[res.data.order]);
            }
            else {
                setIsloading(false);
                toast.warning("Check your connection")
            }
        })
    }

    const handleSearchByMobile = async (e) => {
        if (e.target.value.length === 10) {
            const filtervalue = e.target.value
            e.preventDefault();
            if (filtervalue) {
                fetchFilterDataAPI('center-get-filter-order-by-mobile-number-data', filtervalue).then((res) => {
                    if (res.data.status === 1) {
                        setAgentOrder(...[res.data.order]);
                    }
                    else {
                        toast.warn("Data not founds");
                    }
                })
            }
            else {
                getAllCenterCreatedOrder(centerid)
            }
        }
    };
    const refreshOrderButton = () => {
        getAllCenterCreatedOrder(centerid)
    }
    return (
        <div className="misDashboard">
            <Helmet>
                <title>SATKARTAR:CENTER::ALL ORDERS</title>
            </Helmet>
            <ContentWrapper>
                <div className="page-wrapper breadcum">
                    <div className="page-breadcrumb d-none d-sm-flex align-items-center justify-content-between">
                        <div className="">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 p-0">
                                    <li className="breadcrumb-item">
                                        <Link to="/dashboard">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item" aria-current="page">All ORDERS</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="d-sm-flex">
                            <input  style={{ width: "250px" }} name="cmobile" id="cmobile" className="form-control" type="text" placeholder="Search by Mobile Number" minLength={10} maxLength={10} pattern="[6789][0-9]{9}" onKeyUp={handleSearchByMobile} />
                            <Button onClick={refreshOrderButton} className="btn-sm btn-secondary"><FiRefreshCcw /> Refresh</Button>
                        </div>
                    </div>
                    <div className="card tablecard">
                        <div className="table-responsive">
                            <Table className="table-sm" responsive="lg" striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Order No.</th>
                                        <th>Name</th>
                                        <th>Mobile</th>
                                        <th>Sub Total</th>
                                        <th>Advance Amt</th>
                                        <th>Discount Price</th>
                                        <th>Balance Amt</th>
                                        <th>Total Price</th>
                                        <th>Order Date</th>
                                        <th>Order Time</th>
                                        <th>Delivery Status</th>
                                        <th>Order Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {isLoading ?
                                        <>
                                            <tr>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                            </tr>
                                            <tr>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                            </tr>
                                            <tr>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                            </tr>
                                            <tr>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                            </tr>
                                            <tr>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                            </tr>
                                            <tr>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                            </tr>
                                            <tr>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                            </tr>
                                            <tr>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                            </tr>
                                            <tr>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                            </tr>
                                            <tr>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                            </tr>
                                        </>

                                        :
                                        records?.map((records, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td><Badge bg="success">{records?.order_number}</Badge></td>
                                                <td>{records?.order_name}</td>
                                                <td>{records?.order_mobile}</td>
                                                <td><Badge bg="secondary">{numberFormat(records?.sub_total)}</Badge></td>
                                                <td><Badge bg="warning">{numberFormat(records?.order_advance_amt)}</Badge></td>
                                                <td><Badge bg="danger">{numberFormat(records?.total_disc)}</Badge></td>
                                                <td><Badge bg="info">{numberFormat(records?.total_balance)}</Badge></td>
                                                <td><Badge bg="primary">{numberFormat(records?.grand_total)}</Badge></td>
                                                <td><Badge bg="success">{moment(records?.created_date).format('Do MMMM YYYY')}</Badge ></td>
                                                <td>{records?.created_time}</td>
                                                <td>{records?.shipping_status}</td>
                                                <td>{records?.order_confirm_status}</td>
                                                <td><Link to={`/invoice/${records.order_number}`} className="badge badge-success"><FaRegEye style={{ fontSize: "20px" }} /></Link></td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th>#</th>
                                        <th>Order No.</th>
                                        <th>Name</th>
                                        <th>Mobile</th>
                                        <th>Sub Total</th>
                                        <th>Advance Amt</th>
                                        <th>Discount Price</th>
                                        <th>Balance Amt</th>
                                        <th>Total Price</th>
                                        <th>Order Date</th>
                                        <th>Order Time</th>
                                        <th>Delivery Status</th>
                                        <th>Order Status</th>
                                        <th>Action</th>
                                    </tr>
                                </tfoot>
                            </Table>
                            <nav>
                                <ul className="pagination justify-content-end round-pagination">
                                    <li className="page-item">
                                        <a href="#" className="page-link" onClick={perPage}>Previous</a>
                                    </li>
                                    {
                                        numbers.map((n, i) => (
                                            <li className={`page-item ${currentPage === n ? 'active' : ''}`} key={i}>
                                                <a href="#" className="page-link" onClick={() => changePage(n)}>{n}</a>
                                            </li>
                                        ))
                                    }
                                    <li className="page-item">
                                        <a href="#" className="page-link" onClick={nextPage}>Next</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
    function perPage() {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }
    }
    function changePage(id) {
        setCurrentPage(id)
    }
    function nextPage() {
        if (currentPage !== npage) {
            setCurrentPage(currentPage + 1)
        }
    }
}

export default AllOrder