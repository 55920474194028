import React, { useEffect, useState } from 'react'
import { AiOutlineDeliveredProcedure } from 'react-icons/ai';
import { FaTruck } from 'react-icons/fa';
import { FiRefreshCcw } from 'react-icons/fi';
import { IoToday } from "react-icons/io5";
import { fetchAllDashboardDataAPI } from '../../utils/api';
import { toast } from 'react-toastify';

const DashboardOverview = () => {
    const centerid = localStorage.getItem('centerdata');
    const [deliveredorder, setDeliveredOrder] = useState([]);
    const [pendingorder, setPendingOrder] = useState([]);
    const [neworder, setNewOrder] = useState([]);
    const [processorder, setProcessOrder] = useState([]);
    const [totalorder, setTotalOrder] = useState([]);
    

    useEffect(() => {
        getDashboardAllOrderTypeAgentOrder(centerid);
    }, [centerid])

    const getDashboardAllOrderTypeAgentOrder = (centerid) => {
        fetchAllDashboardDataAPI('center-get-dashboard-all-order-type-data', centerid).then((res) => {
            if (res.data.status === 1) {
                setDeliveredOrder(...[res.data.delivered]);
                setPendingOrder(...[res.data.pending]);
                setNewOrder(...[res.data.neworder]);
                setProcessOrder(...[res.data.process]);
                setTotalOrder(...[res.data.totalorder]);
            }
            else {
                toast.warning("Check your connection")
            }
        })
    }
    return (
        <div className="dashboardoverview">
            <div className="card radius-10 overflow-hidden w-100">
                <div className="d-flex overbody">
                    <div className="icons" style={{ background: "#4e9191" }}>
                        <IoToday />
                    </div>
                    <div className="ordertype">
                        <p>New Orders</p>
                        <h2>{neworder}</h2>
                    </div>
                </div>
            </div>
            <div className="card radius-10 overflow-hidden w-100">
                <div className="d-flex overbody">
                    <div className="icons" style={{ background: "#0e7c52" }}>
                        <FiRefreshCcw />
                    </div>
                    <div className="ordertype">
                        <p>Order Pending</p>
                        <h2>{pendingorder}</h2>
                    </div>
                </div>
            </div>
            <div className="card radius-10 overflow-hidden w-100">
                <div className="d-flex overbody">
                    <div className="icons" style={{ background: "#00CED1" }}>
                        <FaTruck />
                    </div>
                    <div className="ordertype">
                        <p>Order Processing</p>
                        <h2>{processorder}</h2>
                    </div>
                </div>
            </div>
            <div className="card radius-10 overflow-hidden w-100">
                <div className="d-flex overbody">
                    <div className="icons" style={{ background: "#c779db" }}>
                        <AiOutlineDeliveredProcedure />
                    </div>
                    <div className="ordertype">
                        <p>Order Delivered</p>
                        <h2>{deliveredorder}</h2>
                    </div>
                </div>
            </div>
            <div className="card radius-10 overflow-hidden w-100">
                <div className="d-flex overbody">
                    <div className="icons" style={{ background: "#597e0d" }}>
                        <IoToday />
                    </div>
                    <div className="ordertype">
                        <p>Total Orders</p>
                        <h2>{totalorder}</h2>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DashboardOverview