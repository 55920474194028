import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.scss";
import Home from "./pages/Home";
import Error404 from "./pages/Error404";
import Dashboard from "./pages/Dashboard";
import MobileOTP from "./pages/MobileOTP";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import MyProfile from "./pages/MyProfile";
import 'react-loading-skeleton/dist/skeleton.css'
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import AllOrder from "./pages/AllOrder";
import AddMember from "./pages/AddMember";
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'mdbreact/dist/css/mdb.css';
import EditMember from "./pages/EditMember";
import AddAgent from "./pages/AddAgent";
import Member from "./pages/Member";
import Agent from "./pages/Agent";
import EditAgent from "./pages/EditAgent";
import Invoice from "./pages/Invoice";


function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/mobile-otp" element={<MobileOTP />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/my-profile" element={<MyProfile />} />
          <Route path="/all-orders" element={<AllOrder />} />
          <Route path="/member" element={<Member />} />
          <Route path="/add-member" element={<AddMember />} />
          <Route path="/edit-member/:slug" element={<EditMember />} />
          <Route path="/agent" element={<Agent />} />
          <Route path="/add-agent" element={<AddAgent />} />
          <Route path="/edit-agent/:slug" element={<EditAgent />} />
          <Route path="/invoice/:slug" element={<Invoice />} />
          <Route path="*" element={<Error404 />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer position="bottom-center" />
    </>
  );
}

export default App;
